import React from 'react';
import { Table, List, ThemeIcon, Spoiler } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import team1 from '../../assets/images/team-1-1.jpg';

const Ophthalmology = () => {
  const Adminstaffs = [
    {
      name: 'Dr. Omoniyi Oluwasesan GIWA',
      Qualification: 'Natinal Diploma',
      Designation: 'Higher Executive Officer',
    },
    {
      name: 'Mrs. Aderinola Maria SANUSI',
      Qualification: 'National Diploma',
      Designation: 'Executive Officer',
    },
    {
      name: 'Miss Lilian Ugbaku ACHULONU',
      Qualification: 'Senior School Certificate',
      Designation: 'Senior Processing Assistant1',
    },
    {
      name: 'Mr Toheeb Busayo AJELARA',
      Qualification: 'Senior School Certificate',
      Designation: 'Senior Clerical Officer',
    },
    {
      name: 'Mr Mayowa Rotimi OLAOSEBIKAN',
      Qualification: 'Senior School Certificate',
      Designation: 'Clerical Assistant/Dispensing Optician',
    },
  ];

  const rows = Adminstaffs.map((element) => (
    <tr key={element.name}>
      <td>{element.name}</td>
      <td>{element.Qualification}</td>
      <td>{element.Designation}</td>
    </tr>
  ));

  const medicalstaffs = [
    {
      name: 'Mrs. Abiola OLAWEPO',
      Qualification:
        'HND Health Information Management, Certificate in Health Planning Management',
      Designation: 'Assistant Chief Health Information Management Technologist',
    },
    {
      name: 'Mrs. Mary AKANNI',
      Qualification:
        'HND Health Information Management, MSc Education Planning Management',
      Designation: 'Health Information Management Technologist',
    },
    {
      name: 'Mr. Ibukun OLADOKUN',
      Qualification: 'HND Mechanical Engineering',
      Designation: 'Clerical Officer',
    },
    {
      name: 'Mrs. Moriam KAZEEM',
      Qualification: 'ND Business Administration',
      Designation: 'Clerical Officer',
    },
  ];

  const medicalrows = medicalstaffs.map((element) => (
    <tr key={element.name}>
      <td>{element.name}</td>
      <td>{element.Qualification}</td>
      <td>{element.Designation}</td>
    </tr>
  ));

  const anterior = [
    {
      name: '	Cataract surgical services ',
    },
    {
      name: 'Nd:Yag Laser capsulotomy',
    },
    { name: '	Corneal services' },
    {
      name: '	Enucleation/evisceration/exenteration ',
    },

    {
      name: '	Pterygium services',
    },
    {
      name: '	Ocular trauma services',
    },
    { name: 'General ophthalmic services' },
  ];
  const glaucoma = [
    { name: ' Glaucoma services (medical and surgical)' },
    { name: 'Trabeculectomy' },
    { name: ' Selective Laser Trabeculoplasty' },
    { name: ' Laser iridotomy' },
    { name: ' General ophthalmic services' },
    { name: 'Pachymetry' },
    { name: 'Optical coherence tomography' },
  ];
  const Paediatric = [
    { name: 'Paediatric cataract services' },
    { name: 'Paediatric glaucoma services' },
    { name: 'Paediatric oncology services' },
    { name: 'Paediatric refraction services' },
    { name: 'Anterior vitrectomy' },
    { name: 'Enucleation/evisceration ' },
    { name: 'Paediatric ocular trauma services' },
    { name: 'General ophthalmic services' },
    { name: 'Retinopathy of prematurity screening' },
  ];
  const vitreoretina = [
    { name: 'Retinal laser services' },
    { name: 'Scleral buckle' },
    { name: 'Intravitreal injection' },
    { name: 'Optical coherence tomography' },
    { name: 'General ophthalmic services' },
    { name: 'Diabetic eye screening services' },
  ];
  const refraction = [
    { name: 'Adult refraction services' },
    { name: 'Low vision services' },
  ];
  const community = [
    {
      name:
        'Community eye care services at LUTH Comprehensive Health Centre, Pakoto, Ogun State.',
    },
  ];
  return (
    <Layout pageTitle='Department of Ophthalmology | LUTH'>
      <NavOne />
      <PageHeader title='Department of Ophthalmology' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Ophthalmology
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The Department, of Ophthalmology has been in existence
                        since 1968 as a Unit within Department of Surgery of the
                        Lagos University Teaching Hospital (LUTH), Idi-Araba,
                        Lagos, under the Headship of Professor Istvan Rethy, a
                        Hungarian who was solely responsible for administration,
                        Clinical, Surgical management of patients and Teaching
                        of Medical and Dental students. The Unit over the years
                        gradually grew into an essential part of the Department
                        of Surgery under various Heads of Unit, namely Professor
                        A.A. Majekodunmi and Professor A. O. Adefule-Ositelu. In
                        1993 December, it moved to its present site the Guinness
                        Eye Centre (GEC), a building built and donated by
                        Guinness Nigeria PLC and has since remained autonomous
                        while functioning in the Department of Surgery until
                        September 2008 when it became a Department in the
                        College of Medicine of the University of Lagos/ Lagos
                        University Teaching Hospital (LUTH), Idi-Araba following
                        the approval by the Senate of University of Lagos.
                        Guinness Eye Centre provides comprehensive ophthalmic
                        care services to a wide range of patients from Lagos,
                        other States in Nigeria especially neighbouring States
                        as well as neighbouring countries. It provides optical,
                        medical, laser and surgical eye care services. The
                        Department has continued to train undergraduate medical
                        and ophthalmic nursing Students, as well as Postgraduate
                        residents in Ophthalmology and related disciplines. The
                        Department serves as Examination centers for National
                        Postgraduate Medical College of Nigeria and
                        International Council of Ophthalmology Examination.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                      Anterior Segment
                    </h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {anterior.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                    <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                      Glaucoma
                    </h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {glaucoma.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                    <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Paediatric Ophthalmology and Strabismus
                    </h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {Paediatric.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                    <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Vitreoretina
                    </h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {vitreoretina.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                    <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Refraction and low vision
                    </h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {refraction.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                    <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Community/ Public Health Ophthalmology
                    </h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {community.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Kareem Olatunbosun MUSA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACS, FMCOphth, FICO, ICO Fellow (Paediatric
                            Ophthalmology)
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Head of Department/Associate Professor/ Consultant
                            Ophthalmologist
                            <br />
                            <span className='detail-head'>Sub-Specialty:</span>
                            Paediatric Ophthalmology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Folasade Bolanle AKINSOLA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MSc (CEH) UK, FWACS, FMCOphth, MD
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Professor/Consultant Ophthalmologist
                            <br />
                            <span className='detail-head'>Sub-Specialty:</span>
                            Anterior segment/Community Ophthalmology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Adeola Olukorede ONAKOYA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACS, FMCOphth, FICO, MD
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Professor/Consultant Ophthalmologist
                            <br />
                            <span className='detail-head'>Sub-Specialty:</span>
                            Glaucoma
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olufisayo Temitayo ARIBABA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MSc (CEH) UK FWACS, FMCOphth, FICS
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Associate Professor/Consultant Ophthalmologist
                            <br />
                            <span className='detail-head'>Sub-Specialty:</span>
                            Anterior Segment/Community Eye Health
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Adetunji Olusesan ADENEKAN</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCOph
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Consultant Ophthalmologist/ Senior Lecturer
                            <br />
                            <span className='detail-head'>Sub-Specialty:</span>
                            Vitreoretinal Surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olubanke Theodora ILO</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACS, FMCOph
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Consultant Ophthalmologist/ Senior Lecturer
                            <br />
                            <span className='detail-head'>Sub-Specialty:</span>
                            Anterior Segment/Refraction and Low Vision
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Abayomi Felix ENIOLA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BSc Optometry & OD (Doctor of Optometry), MSc Public
                            Health
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Chief Optometrist
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olufunke Adebusola KAMSON</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BSc Optometry & OD (Doctor of Optometry)
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Assistant Chief Optometrist
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Gloria Orenonsen ADESEGHA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BSc Optometry & OD (Doctor of Optometry)
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Optometrist
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Administrative Staff
                      </h2>
                      <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Qualification</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                      </Table>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Medical Staff
                      </h2>
                      <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Qualification</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>{medicalrows}</tbody>
                      </Table>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:ophthalmology@luth.gov.ng'>                        
                        ophthalmology@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Ophthalmology;
